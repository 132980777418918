<template>
  <layout>
    <template #secondarySidebar>
      <scheduler-nav />
    </template>

    <alert-warning v-if="! state.hasAccess" title="Access denied">
      You are not allowed to edit this schedule
    </alert-warning>

    <div v-else>
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">{{ state.schedule.name }}</h2>
        <router-link :to="{ name: 'SchedulerSchedulesIndex'}" class="group">
          <div class=" flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="space-y-6 xl:space-y-8 mt-6 xl:mt-8">
        <schedule-form
          v-if="state.schedule"
          @created="redirectOnCreated"
          :is-loading="state.isInitialLoading"
          :permissions="state.permissions"
          :edit-mode="true"
          :selector-machines="state.machines"
          :form="state.schedule"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive } from 'vue'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import notification from '@/services/notifications.js'
import Layout from '@/layouts/Default.vue'
import AlertWarning from '@/components/alerts/Warning.vue'
import ScheduleForm from '@/components/schedules/ScheduleForm.vue'
import { useRouter, useRoute } from 'vue-router'
import SchedulerNav from '@/components/schedules/Nav.vue'

export default {
  name: 'schedules-edit',

  components: {
    Layout,
    AlertWarning,
    ScheduleForm,
    SchedulerNav
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const scheduleId = route.params.id

    const state = reactive({
      hasAccess: true,
      isInitialLoading: false,
      schedule: {},
      machines: []
    })

    const getSchedule = async (scheduleId) => {
      state.isInitialLoading = true

      try {
        const userResponse = await http.get('user/profile')
        const isAdmin = userResponse.data.is_administrator

        const { data } = await http.get(`user/machines`)
        state.machines = isAdmin ? data.allowed_locations : data.allowed_locations.filter(machine => machine.is_owner)

        const schedulesResponse = await Promise.all(state.machines.map((machine) => {
          return http.get(`machine/${machine.location_id}/schedule`)
            .then(({ data }) => {
              return {
                ...data,
                machine
              }
            })
        }))

        const groupedSchedules = schedulesResponse.reduce(
          (all, obj) => {
            for (let schedule of obj.schedule) {
              if (all[schedule.schedule_id]) {
                all[schedule.schedule_id].machines.push(obj.machine.location_id)
                if (obj.can_edit === false) {
                  all[schedule.schedule_id].can_edit = false
                }
              }
              else {
                all[schedule.schedule_id] = {
                  ...schedule,
                  machines: [obj.machine.location_id],
                  can_edit: obj.can_edit,
                  // name: (() => {
                  //   const modString = '(2)'
                  //   return schedule.name.includes(modString) ? schedule.name : `${schedule.name} ${modString}`
                  // })(),
                  schedule_times: schedule.schedule_times.map((times) => {
                    return {
                      stop: date.timeFormat(times.stop).fromApi(),
                      start: date.timeFormat(times.start).fromApi(),
                    }
                  })
                }
              }
            }
            return all
          },
          Object.create(null)
        )

        if (groupedSchedules[scheduleId] && groupedSchedules[scheduleId].can_edit) {
          state.schedule = groupedSchedules[scheduleId]
        }
        else {
          state.hasAccess = false
        }
      } catch (e) {
        if (e.response) {
          notification.error(e.response.data.Message)
        } else {
          notification.error('Something went wrong. Please, try again.')
        }
      }

      state.isInitialLoading = false
    }

    getSchedule(scheduleId)

    const redirectOnCreated = () => {
      router.push({ name: 'SchedulerSchedulesIndex' })
    }

    return {
      state,
      redirectOnCreated,
    }
  }
}
</script>
